<template>
	<div style="background: rgba(247, 247, 247, 1);padding-right: 40rem">
		<div class="title" style="position: relative">
      <img class="bg" src="../../../../assets/img/fresh-registration-details-bg.png" alt="" />
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">基础信息</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="form.info.student_name" label="学生姓名"></Item>
					<Item :value="form.info.name" label="监护人姓名"></Item>
					<Item :value="form.info.relationship_name" label="监护人和学生关系" label-width="140rem"></Item>
					<Item :value="form.info.customer_phone" label="签约手机号"></Item>
				</div>
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="form.info.prepare_study_subject_type_text" label="计划学习科目"></Item>
					<Item :value="form.info.prepare_study_type_text" label="计划学习方向"></Item>
					<Item :label="form.info.prepare_major?'专业方向':''" :value="form.info.prepare_major" label-width="140rem"></Item>
					<Item label="" value=""></Item>
				</div>
			</div>
		</div>

		<div class="title">
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">学生个人资料</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value=" form.student.sex" label="学生性别"></Item>
					<Item :value="form.student.birthday" label="学生生日"></Item>
					<Item label="学生民族" :value="form.student.student_nation"></Item>
					<Item label="学生身份证号码" :value="form.student.student_idcard.replace(/^(.{6}).+(.{4})$/, '$1****$2')"></Item>
				</div>
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="form.student.student_register" label="学生户籍地"></Item>
					<Item :value="form.student.student_communication" label="学生通信地址"></Item>
					<Item :value="form.student.parent_idcard.replace(/^(.{6}).+(.{4})$/, '$1****$2')" label="家长身份证号码"></Item>
					<Item value="" label=""></Item>
				</div>
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="`${form.student.student_height}`" label="学生身高"></Item>
					<Item :value="`${form.student.student_weight}`" label="学生体重"></Item>
					<Item :value="form.student.student_shoe_size" label="鞋子尺码"></Item>
					<Item :value="form.student.preselection_uniform_size" label="预定校服尺码"></Item>
				</div>
			</div>
		</div>

		<div class="title">
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">历史学习信息</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="form.history_learning.student_attend_graduation" label="高中毕业时间" label-width="140rem"></Item>
					<Item :value="form.history_learning.student_attend_road_text" label="就读途径"></Item>
          <Item value="" label=""></Item>
          <Item value="" label=""></Item>
				</div>
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value=" form.history_learning.graduation_area_text" label="毕业学校区域" label-width="140rem"></Item>
					<Item :value="form.history_learning.student_attend_graduation_school" label="高中毕业学校"></Item>
					<Item :value="form.history_learning.student_attend_graduation_class" label="高中毕业班级"></Item>
					<Item value="" label=""></Item>
				</div>
			</div>
		</div>

		<div class="title">
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">高考成绩</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value="form.nnce.study_subject_type_text" label="高中学习科目"></Item>
					<Item :value="form.nnce.student_high_languages_text" label="外语语种"></Item>
					<Item :value="form.nnce.student_high_direction_text" label="高中学习方向"></Item>
					<Item :value="form.nnce.major" label="专业方向" v-if="form.nnce.major"></Item>
					<Item :value="form.nnce.senior" label="高考考试区域"></Item>
				</div>
        <div style="padding-top: 28rem;padding-right: 24rem">
          <el-table :data="form.nnce.exam_results.list" :header-cell-style="{background: '#F0F7FF'}" :stripe="false" style="width: 100%">
            <el-table-column v-for="item in form.nnce.exam_results.header" :label="item.label" :prop="item.prop" :width="item.width"/>
          </el-table>
        </div>
			</div>
		</div>

    <div class="title">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">订单信息</div>
      </div>
      <div class="title-bottom">
        <div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
          <Item :value="order.sign_order.order_number" label="商品订单号"></Item>
          <Item :value="order.sign_order.status_text" label="商品订单状态"></Item>
          <Item :value="order.sign_order.standard_name" label="购买商品规格"></Item>
          <Item value="" label=""></Item>
        </div>
        <div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
          <Item :value="order.sign_order.payment_number" label="支付订单号"></Item>
          <Item :value="order.sign_order.pay_status_text" label="支付订单状态"></Item>
          <Item :value="order.sign_order.pay_time" label="支付成功时间"></Item>
          <Item :value="order.sign_order.money_actual" label="支付金额"></Item>
        </div>
        <div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
          <Item :value="order.sign_order.sign_teacher" label="签约招生老师"></Item>
          <Item :value="order.sign_order.belong_teacher" label="当前客户归属人"></Item>
          <Item value="" label=""></Item>
          <Item value="" label=""></Item>
        </div>
        <div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
          <Item :value="order.sign_order.formal_sign_time" label="签约时间"></Item>
          <Item :value="order.sign_order.agreement_sn" label="协议编号"></Item>
          <Item :value="order.sign_order.formal_school_sn" label="报名学校顺序号"></Item>
          <Item value="" label=""></Item>
        </div>
      </div>
    </div>

		<div class="body">
			<div class="body-title">备注内容</div>
			<div class="body-bottom" style="padding-top: 28rem">
				<el-table :data="remarkData" :header-cell-style="{background: '#F0F7FF'}" :stripe="false" style="width: 100%">
					<el-table-column v-for="item in remarksTableConfig" :label="item.label" :prop="item.prop" :width="item.width"/>
				</el-table>
			</div>
		</div>

		<div class="button" style="margin-top: 76rem">
			<el-button @click="$router.back()">返回</el-button>
			<el-button style="margin-left: 32rem" type="primary" @click="viewContract">查看合同</el-button>
			<el-button style="margin-left: 32rem" type="primary" @click="addNotes">添加备注</el-button>
		</div>

		<el-dialog :visible.sync="remarkVisible" custom-class="remark-box" title="添加备注" width="850rem" @close="remarkClose">
			<div>
				<el-input v-model="remark" maxlength="300" placeholder="请输入" resize="none" rows="8" show-word-limit type="textarea"></el-input>
			</div>
			<div>
			</div>
			<span slot="footer" class="dialog-footer">
        <el-button @click="()=>remarkVisible=false">取消</el-button>
        <el-button :disabled="!remark" type="primary" @click="remarkHandle">保存</el-button>
      </span>
		</el-dialog>
    <el-dialog :visible.sync="agreement" custom-class="remark-box" title="查看合同" width="850rem" @close="remarkClose">
      <span v-if="JSON.stringify(agreementData) !== '[]'" @click="onHerf" style="color: #1B7EF2;cursor: pointer">{{agreementData[0].service_name}}</span>
      <t-result type="empty" v-else></t-result>
		</el-dialog>
	</div>
</template>
<script>

import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"
import TResult from "@/components/Result.vue";

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  components: {TResult, ListTemplate, Item},
	data() {
		return {
			OSS_URL: process.env["VUE_APP_URL_OSS"],
      remarksTableConfig: [
				{prop: 'content', label: '备注内容', showOverflowTooltip: false},
				{prop: 'creator', label: '创建人'},
				{prop: 'created_at', label: '创建时间'},
			],
      achievementTableConfig: [
				{prop: 'header_key_0', label: '总分', showOverflowTooltip: false},
				{prop: 'header_key_1', label: '语文'},
				{prop: 'header_key_2', label: '数学'},
				{prop: 'header_key_3', label: '外语'},
				{prop: 'header_key_4', label: '政治'},
				{prop: 'header_key_5', label: '历史'},
				{prop: 'header_key_6', label: '地理'},
			],
			form: {
        info: {
          info_id: "",
          student_name: "",
          name: "",
          relationship_name: "",
          customer_phone: "",
          prepare_study_subject_type_text: "",
          prepare_study_type_text: "",
          prepare_major: ""
        },
        student: {
          sex: "",
          birthday: "",
          student_nation: "",
          student_register: "",
          student_communication: "",
          student_idcard: "",
          parent_idcard: "",
          student_height: "",
          student_weight: "",
          student_shoe_size: "",
          preselection_uniform_size: ""
        },
        history_learning: {
          student_attend_graduation: "",
          student_attend_road_text: "",
          graduation_area_text: "",
          student_attend_graduation_school: "",
          student_attend_graduation_class: ""
        },
        nnce: {
          study_subject_type_text: "",
          student_high_languages_text: "",
          student_high_direction_text: "",
          major: "",
          senior: "",
          exam_results: {
            list:[],
            header:[]
          }
        }
			},
      order: {
        sign_order: {
          order_number: "", // String, 商品订单号
          status: 0, // Int, 订单状态 1待支付 2已取消 3待签约 4已完成 5已退款
          status_text: "", // String, 订单状态说明
          standard_name: "", // String, 购买商品规格
          payment_number: "", // String, 支付订单号
          pay_status_text: "", // String, 支付状态说明
          pay_time: "", // String, 支付成功时间
          money_actual: "", // String, 支付金额
          sign_teacher: "", // String, 入学报名招生老师
          belong_teacher: "", // String, 当前客户归属人
          formal_sign_time: "", // String, 入学签约时间
          agreement_sn: "", // String, 入学协议编号
          formal_school_sn: "", // String, 正式报名学校顺序号
        },
			},
			remarkVisible: false,
      agreement: false,
			remark: '',
			modifyStatus: 0,
      remarkData: [],
      agreementData: [{
        service_name:'',
        other_url:'',
      }]
		}
	},
	methods: {
		addNotes() {
			this.remarkVisible = true
		},
    viewContract() {
      this.$_register.get('api/recruit/fresh-graduate/contract',{params:{info_id:this.form.info.info_id}}).then(res=>{
        this.agreementData = res.data.data
        this.agreement = true
      })
		},
		remarkClose() {
			this.remark = ''
		},
		remarkHandle() {
			this.$_register.post('api/recruit-v2/repeat-manage/remark', {order_id: this.$route.query.id, content: this.remark}).then(() => {
				this.$message.success('添加成功')
				this.remarkVisible = false
				this.getRemark()
			})
		},
    getRemark(){
      this.$_register.get('api/recruit/fresh-graduate/remark-list',{params:{id:this.$route.query.id}}).then(res => {
        this.remarkData = res.data.data;
      })
    },
    onHerf(){
      window.open(this.agreementData[0].other_url,'_blank')
    }
	},

	created() {
		this.$_register.get('api/recruit-v2/repeat-manage/detail?id=' + this.$route.query.id).then(res => {
			this.form = res.data.data;
		})
		this.$_register.get('api/recruit-v2/repeat-manage/order-cost?id=' + this.$route.query.id).then(res => {
			this.order = res.data.data;
		})
    this.getRemark()
	}
}
</script>
<style lang="scss" scoped>
.title-title {
	font-size: 18rem;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 60rem;
	color: rgba(0, 0, 0, 0.8);
	border-bottom: 1rem solid #F7F7F7;
	border-radius: 10rem 10rem 0 0;
	background: #FFF;
	box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
	box-sizing: border-box;
	margin-bottom: 24rem;
	//padding-top: 28rem;
	padding-bottom: 24rem;
	padding-left: 32rem;
	border-radius: 0 0 10rem 10rem;
	background: #FFF;
}

.body {
	margin: 24rem 0;

	& .body-title {
		font-size: 18rem;
		font-weight: bold;
		box-sizing: border-box;
		height: 78rem;
		padding-top: 19rem;
		padding-left: 24rem;
		color: rgba(0, 0, 0, 0.8);
		border-bottom: 1rem solid #F7F7F7;
		border-radius: 10rem 10rem 0 0;
		background: #FFFFFF;
		box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
	}

	& .body-bottom {
		box-sizing: border-box;
		min-height: 148rem;
		padding: 0 24rem 24rem;
		border-radius: 0 0 10rem 10rem;
		background: #FFFFFF;
		box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

		& .body-bottom-label {
			font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
			font-size: 14rem;
			font-weight: 400;
			width: 96rem;
			color: rgba(0, 0, 0, 0.8);
		}
	}
}

.el-table__body .cell {
	white-space: normal;
	word-wrap: break-word;
}

.bg {
	position: absolute;
	top: 0;
	right: 0;
	width: 220rem;
	height: 60rem;
}
</style>
